import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import * as Yup from 'yup';

import { ActionsFooter, ProfilePicture, Checkbox, FormField, OnboardingLayoutContent } from '@getro/rombo';
import { getUserDisplayName } from '../../../helpers/users';
import ResumeFields from './ResumeFields';
import ProfileSectionTitle from '../../atoms/ProfileSectionTitle';

import LINKEDIN_URL_REGEX from '../../../constants/linkedin';
import STRINGS from '../../../constants/strings';
import SearchLocation from '../SearchLocation';

const formSchema = Yup.object().shape({
  bio: Yup.string().required(STRINGS.validation.requiredField).max(150).nullable(),
  currentLocation: Yup.object().nullable().required(STRINGS.validation.requiredField),
  linkedinUrl: Yup.string()
    .matches(LINKEDIN_URL_REGEX, {
      message: STRINGS.validation.linkedinUrlError,
    })
    .nullable(),
  websiteUrl: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      STRINGS.validation.urlError,
    )
    .nullable(),
});

const PersonalInformationForm = ({
  disableSave,
  errorMessage,
  initialValues,
  inUserProfile,
  networkName,
  onBack,
  onSubmit,
  saveText,
  title,
  updating,
  mentorshipDeactivated,
}) => (
  <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
    {(form) => {
      const { dirty, values, setFieldValue } = form;
      const { avatarUrl, bio, currentLocation, mentorship, resume, resumeUrl, verified } = values;

      return (
        <Form>
          <OnboardingLayoutContent title={title}>
            {inUserProfile && (
              <Flex sx={{ columnGap: 4 }} flexDirection={['column', 'row']}>
                <Box flex="1">
                  <Field type="text" name="firstName" component={FormField} label="First name" />
                </Box>
                <Box flex="1" mt={[3, 0]}>
                  <Field type="text" name="lastName" component={FormField} label="Last name" />
                </Box>
              </Flex>
            )}

            <ProfileSectionTitle>Upload your profile image (optional)</ProfileSectionTitle>

            <Flex alignItems="center">
              <Box
                mr={4}
                sx={{
                  flexBasis: '88px',
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <ProfilePicture imageUrl={avatarUrl} name={getUserDisplayName(values)} verified={verified} />
              </Box>
            </Flex>

            <ProfileSectionTitle>Upload your resume (optional)</ProfileSectionTitle>
            <ResumeFields resume={resume} resumeUrl={resumeUrl} setFieldValue={setFieldValue} />

            <ProfileSectionTitle>Bio and work history</ProfileSectionTitle>
            <FormField
              field={{ name: 'bio' }}
              label="Use this space to highlight what motivates you in your career. This will be what companies first see on your profile (150 chars max)."
              as="textarea"
              value={bio}
              form={form}
              onChange={(e) => setFieldValue('bio', e.target.value)}
            />
            <Box width={[1, 0.5]} mt="4">
              <Field
                component={(data) => (
                  <SearchLocation
                    name="currentLocation"
                    value={currentLocation}
                    onChange={(e) => setFieldValue('currentLocation', e.target.value)}
                    label="Current location"
                    {...data}
                  />
                )}
              />
            </Box>

            <ProfileSectionTitle>Where can we find you online?</ProfileSectionTitle>

            <Field type="text" name="websiteUrl" component={FormField} label="Personal website (optional)" />

            <Field type="text" name="linkedinUrl" component={FormField} label="LinkedIn (optional)" />

            <Field type="text" name="dribbbleUrl" component={FormField} label="Dribbble (optional)" />

            <Field type="text" name="githubUrl" component={FormField} label="GitHub (optional)" />

            {!mentorshipDeactivated && (
              <>
                <ProfileSectionTitle>Mentorship (optional)</ProfileSectionTitle>
                <Flex>
                  <Field name="mentorship">
                    {(data) => (
                      <Checkbox
                        label={`I'd be interested in mentoring other members of the ${networkName} community.`}
                        name="mentorship"
                        checked={mentorship}
                        onChange={(e) => setFieldValue('mentorship', e.target.checked)}
                        {...data}
                      />
                    )}
                  </Field>
                </Flex>
              </>
            )}

            <ActionsFooter
              saveDisabled={disableSave && !dirty}
              saveAsSubmit
              saveText={saveText}
              onBack={onBack}
              errorMessage={errorMessage}
              loading={updating}
            />
          </OnboardingLayoutContent>
        </Form>
      );
    }}
  </Formik>
);

PersonalInformationForm.propTypes = {
  disableSave: PropTypes.bool,
  errorMessage: PropTypes.node,
  initialValues: PropTypes.object.isRequired,
  inUserProfile: PropTypes.bool,
  networkName: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  title: PropTypes.string,
  updating: PropTypes.bool,
  mentorshipDeactivated: PropTypes.bool.isRequired,
};

PersonalInformationForm.defaultProps = {
  disableSave: false,
  errorMessage: null,
  inUserProfile: false,
  onBack: () => {},
  saveText: undefined,
  title: '',
  updating: false,
};

export default PersonalInformationForm;
