/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-next-router';
import { Toast } from '@getro/rombo';
import moment from 'moment';
import App from 'next/app';
import '../styles/index.css';
import { useDispatch } from 'react-redux';
import { wrapper } from '../redux/store';
import AppWrapper from '../components/layouts/AppWrapper';
import CustomThemeProvider from '../providers/customTheme';
import useAnalytics from '../hooks/useAnalytics';
import { detectNetworkMismatch } from '../helpers/ssrHelpers';
import 'react-popper-tooltip/dist/styles.css';
import { verifyLoadedJobAlert } from '../redux/actions/jobAlertActions';
import useUnsubscribeSuccess from '../hooks/useUnsubscribeSuccess';
import { useNetworkAlert } from '../hooks/useNetworkAlert';
import Scripts from '../components/atoms/Scripts';
import { setupGlobalErrorHandlers } from '../config/logger-setup';

// Only setup in server environment
if (typeof window === 'undefined') {
  setupGlobalErrorHandlers();
}

const { publicRuntimeConfig } = getConfig();

const GetroJobsApp = ({ Component, pageProps, err }) => {
  useUnsubscribeSuccess();
  const Layout = Component.Layout || (({ children }) => children);
  const { network } = pageProps;
  const { advancedDesign = {} } = network || {};
  const { trackPageView } = useAnalytics({ network });
  const dispatch = useDispatch();
  const { theme } = advancedDesign || {};

  moment.updateLocale('en', {
    relativeTime: {
      past: '%s',
      s: 'Today',
      ss: 'Today',
      m: 'Today',
      mm: 'Today',
      h: 'Today',
      hh: 'Today',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  });

  useNetworkAlert();

  // this should only fire one time to track the init page view, page change events are tracked in AppWrapper.js
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(verifyLoadedJobAlert());
  }, [dispatch]);

  return useMemo(
    () => (
      <ConnectedRouter>
        {network?.label && network?.hasAdvancedDesign && (
          <Scripts advancedDesign={advancedDesign} label={network.label} />
        )}
        <CustomThemeProvider isV2Onboarding={network?.isV2Onboarding} advancedTheme={theme}>
          <AppWrapper advancedDesign={advancedDesign} {...pageProps}>
            <Layout advancedDesign={advancedDesign} {...pageProps}>
              <Component advancedDesign={advancedDesign} {...pageProps} err={err} />
              <Toast />
            </Layout>
          </AppWrapper>
        </CustomThemeProvider>
      </ConnectedRouter>
    ),
    [network?.label, network?.hasAdvancedDesign, network?.isV2Onboarding, advancedDesign, theme, pageProps, err],
  );
};

GetroJobsApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  const { serverRuntimeConfig } = getConfig();
  appProps.referrer = ctx?.req?.headers?.referer || null;
  const { req, pathname, res } = ctx;

  let resp = {
    network: null,
    advancedDesign: null,
  };
  try {
    const url = typeof window !== 'undefined' ? window.location.origin : `http://127.0.0.1:${serverRuntimeConfig.port}`;
    resp = await fetch(`${url}${publicRuntimeConfig.customPath}/api/network?pathname=${pathname}`, {
      headers: new Headers(Object.entries(req?.headers || {})),
    }).then((response) => response.json());
  } catch (e) {
    resp.network = null;
  }

  const { network, advancedDesign } = resp;
  // Handle 404 case early
  if (!network?.id) {
    if (res && pathname !== '/not-found' && pathname !== '/404' && pathname !== '/robots.txt') {
      res.writeHead(302, { Location: '/not-found' });
      res.end();
      return {
        ...appProps,
        statusCode: 404,
      };
    }
  }

  detectNetworkMismatch({ network, req });

  req.network = network;
  appProps.pageProps.network = network;

  if (advancedDesign && network?.id) {
    appProps.pageProps.network.advancedDesign = advancedDesign;
  }

  return { ...appProps };
};

GetroJobsApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  advancedDesign: PropTypes.shape({
    footer: PropTypes.string,
    header: PropTypes.string,
    theme: PropTypes.string,
    version: PropTypes.string,
  }),
  pageProps: PropTypes.object.isRequired,
  customStylesheet: PropTypes.string,
  err: PropTypes.object,
};

GetroJobsApp.defaultProps = {
  customStylesheet: null,
  err: null,
  advancedDesign: null,
};

export default wrapper.withRedux(GetroJobsApp);
