import logger from '../helpers/logger';

export function setupGlobalErrorHandlers() {
  process.on('uncaughtException', (error) => {
    logger.error({
      error: {
        message: error.message,
        stack: error.stack,
        name: error.name,
        type: 'UncaughtException',
      },
    });
    // Give the logger time to flush before exiting
    setTimeout(() => process.exit(1), 100);
  });

  process.on('unhandledRejection', (reason, promise) => {
    logger.error({
      error: {
        message: reason?.message,
        stack: reason?.stack,
        name: reason?.name,
        type: 'UnhandledRejection',
      },
      promise: promise?.toString(),
    });
  });
}
